import { React, useContext } from 'react';
import Logo from './Logo';
import { Link } from "react-router-dom";
import styled from 'styled-components';
import Menu from './Menu';
import { Context } from "../contexts/AuthContext"

const Header = styled.header`
position: fixed;
font-family: 'Montserrat', sans-serif;
width: 100%;
background-color:#0D1117;
color: white;
text-align: left;
 & ul {
  padding:0px;
  margin:0px;
  list-style:none;
  & li{
  display: inline-block;
  margin: 10px;
  vertical-align : middle;
  font-size: 13px;
  line-height : 15px;
  font-weight: bold;
    & a{
        text-decoration: none;
        color: #fff;
        cursor: pointer;
     }
     & span{
         position: absolute;
         top:8px;
         right: 15px;
     }
     & p{
         position: absolute;
         top:5px;
         right: 65px;
     }
    }
}
`;


export default function HeaderApp() {
    const { user } = useContext(Context);
    return (
        <Header>
            <ul>
                <li><a href='/home'><Logo /></a></li>
                <li><Link to="/home">Home</Link></li>
                <li><Link to="/manutencao">Manutenção</Link></li>
                <li><Link to="/instalacao">Instalação</Link></li>
                <li><Link to="/infra">Infraestrutura</Link></li>
                <li><Link to="/novas-demandas">Novas demandas</Link></li>
                <li><p>{user}</p><span><Menu /></span></li>
            </ul>
        </Header>)
}
