import { useState, useEffect } from "react";
import api from "../api";

export default function useAuth() {
  const [authenticated, setAuthenticated] = useState(false);
  const [user, setUser] = useState('')
  const [permission, setPermission] = useState('')
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("token"));
    const permission = JSON.parse(localStorage.getItem("permission"));
    const user = JSON.parse(localStorage.getItem("user"));

    if(user){
      setAuthenticated(false);
    }

    if (token) {
      api.defaults.headers["x-access-token"] = token;
      setAuthenticated(true);
    }

    setUser(user)
    setPermission(permission)
    setLoading(false);
  }, []);

  async function handleLogin(data) {
    const { user, pass } = data;
    await api
      .post("/login", {
        user: user,
        password: pass,
      })
      .then(function (res) {
        const { user, permission, token } = res.data;

        localStorage.setItem("token", JSON.stringify(token));
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("permission", JSON.stringify(permission));
        api.defaults.headers["x-access-token"] = token;
        setUser(user)
        setPermission(permission)
        setAuthenticated(true);
      })
      .catch(function (res) { 
      });
  }

  function handleLogout() {
    setAuthenticated(false);
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("permission");
    api.defaults.headers["x-access-token"] = undefined;
  }

  return {
    handleLogin,
    handleLogout,
    loading,
    authenticated,
    user,
    permission
  };
}
