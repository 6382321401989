import React from 'react'
import HeaderApp from './HeaderApp'
import FooterApp from './FooterApp'
import {
    Grid,
    makeStyles
} from "@material-ui/core";

const useStyles = makeStyles({
    containerDashboard: {
      paddingTop: 300,
      fontFamily: 'Nunito, sans-serif',
      marginTop: 0,
      '& p':{
        fontSize: 12,
        color: '#aaa'
      }
    }
  });

function Home() {
    const classes = useStyles();
    return (
        <>
            <HeaderApp />
            <Grid container spacing={0} className={classes.containerDashboard}>
                <Grid item xs={12}>
                    <p> em breve aqui terá um dashboard </p> 
                </Grid>
            </Grid>
            <FooterApp />
        </>
    )
}

export default Home;