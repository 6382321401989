import { React } from 'react'
import FooterApp from './FooterApp';
import HeaderApp from './HeaderApp';
import { Grid, Typography } from '@material-ui/core'
import RemoverEquipes from './RemoverEquipes';
import CadastrarEquipes from './CadastrarEquipes'
import { SnackbarProvider } from 'notistack'
import { useNavigate } from "react-router-dom";

function Admin({ theme, permission }) {
    let navigate = useNavigate()
    if (permission === 'user') navigate('/home')

    return (
        <>
            <SnackbarProvider maxSnack={5}>
                <HeaderApp />
                <br />
                <Typography style={{
                    fontFamily: theme.fontPrimary,
                    color: theme.colors.fontHighlight,
                    fontWeight: 'bold',
                    fontSize: theme.titleSize,
                    marginTop: 60
                }}>
                    Configuração equipes
                </Typography>
                <Grid
                    container
                    style={{
                        justifyContent: 'center',
                        marginTop: 20,
                    }}
                    spacing={0}>
                    <Grid item
                        style={{
                            padding: 10,
                            backgroundColor: theme.colors.bgSecundary
                        }}
                        xs={4}>
                        <Typography
                            style={{
                                fontFamily: theme.fontSecundary,
                                color: theme.colors.fontHighlight,
                                fontSize: 16,
                                textAlign: 'left',
                                fontWeight: 'bold',
                            }}>
                            Todos os colaboradores:
                            <p
                                style={{
                                    fontFamily: theme.fontSecundary,
                                    fontSize: 12,
                                    marginTop: 0,
                                    marginBottom: 10,
                                    color: theme.colors.fontAlternative
                                }}>*Selecione os colaborador que deseja incluir:</p>
                        </Typography>
                        <CadastrarEquipes theme={theme} />
                    </Grid>
                    <Grid item
                        style={{
                            marginLeft: 15,
                            padding: 10,
                            backgroundColor: theme.colors.bgSecundary,
                        }}
                        xs={7}>
                        <Typography
                            style={{
                                fontFamily: theme.fontSecundary,
                                color: theme.colors.fontHighlight,
                                fontSize: 16,
                                textAlign: 'left',
                                fontWeight: 'bold',
                            }}>
                            Configuração atual:
                            <p
                                style={{
                                    fontFamily: theme.fontSecundary,
                                    fontSize: 12,
                                    marginTop: 0,
                                    marginBottom: 10,
                                    color: theme.colors.fontAlternative
                                }}>Selecione os colaborador que deseja remover:</p>
                        </Typography>
                        <RemoverEquipes />
                    </Grid>
                </Grid>
                <FooterApp />
            </SnackbarProvider>
        </>
    )
}

export default Admin;