import { React, useEffect, useState } from 'react';
import api from '../api'
import { DataGrid } from '@mui/x-data-grid';
import { Button } from '@mui/material';

const columns = [
    { field: 'name', headerName: 'TÉCNICO', width: 300 },
    { field: 'team', headerName: 'EQUIPE', width: 160 },
    { field: 'company', headerName: 'UNIDADE', width: 120 },
    { field: 'route', headerName: 'ROTA', width: 120 },
    { field: 'occupation_primary', headerName: 'ATIV. PRINCIPAL', width: 120 },
    { field: 'occupation_secundary', headerName: 'ATIV. SECUNDÁRIA', width: 150 }
];

export default function RemoverEquipes() {
    const [responsible, setResponsible] = useState([])
    const [updateTable, setUpdateTable] = useState(false)
    const [rowsIsSelected, setrRowsIsSelected] = useState([])

    async function handleDeleteRow() {
        const newRowsIsSelect = []
        rowsIsSelected.map((rows) => newRowsIsSelect.push({ "id": rows }))
        const token = JSON.parse(localStorage.getItem("token"));
        api.defaults.headers["x-access-token"] = token;
        await api.delete('/api/responsible_requests', { "data": newRowsIsSelect })
            .then(() => setUpdateTable(!updateTable))
    }

    useEffect(() => {
        api.get('/api/responsible_team').then((data) => setResponsible(data.data))
    }, [updateTable])

    return (
        <>
            <div style={{
                height: 380,
                backgroundColor: '#ccc',
                width: '100%',
                textAlign: 'right'
            }}>
                <DataGrid
                    style={{
                        color: 'white',
                        fontSize: 12,
                        backgroundColor: '#0D1117',
                        borderBlockColor:'#0D1117',
                        borderLeftColor:'#0D1117',
                        borderRightColor:'#0D1117',
                        fontWeght: 'bold',
                    }}
                    rows={responsible}
                    columns={columns}
                    pageSize={20}
                    onSelectionModelChange={dados => setrRowsIsSelected(dados)}
                    density="compact"
                    rowsPerPageOptions={[20]}
                    checkboxSelection
                />
                <Button
                    style={{
                        border: '1px dashed #222',
                        backgroundColor: '#0D1117',
                        color: '#ddd',
                        paddingTop: 3,
                        paddingBottom: 3,
                        paddingleft: 10,
                        paddingRight: 10,
                        marginTop: 15,
                        fontWeight: 'Bold'
                    }}
                    onClick={ev => handleDeleteRow()}>Remover colaboradores</Button>
            </div>
        </>
    );
}