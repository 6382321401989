import { React, useState, useContext } from 'react';
import Button from '@mui/material/Button';
import { Context } from "../contexts/AuthContext"
import Menu from '@mui/material/Menu';
import { makeStyles } from '@material-ui/core';
import MenuItem from '@mui/material/MenuItem';
import { Link, useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import ChangePassword from '../components/changePassword'

const useStyles = makeStyles({
    container: {
        textAlign: 'right',
        margin: 0,
        paddingTop: 0
    }
});

export default function BasicMenu() {
    let navigate = useNavigate()
    const classes = useStyles();
    const { handleLogout, authenticated, permission } = useContext(Context);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => setAnchorEl(event.currentTarget)
    const handleClose = () => setAnchorEl(null)
    const handleLogoutClick = () => handleLogout()

    if (!authenticated) navigate("/")

    return (
        <div className={classes.container}>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <MenuIcon htmlColor='#ddd' />
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                variant='menu'
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <ChangePassword />
                {permission === 'admin' ?
                    <MenuItem ><Link to="/admin"
                        style={{
                            textDecoration: 'none',
                            color: '#333',
                            fontSize: 13,
                            textAlign: 'left',
                            fontFamily: 'Nunito, sans-serif'
                        }}>
                        CONFIGURAR EQUIPES</Link></MenuItem> : console.log()}
                <MenuItem onClick={handleLogoutClick}
                    style={{
                        textDecoration: 'none',
                        color: '#333',
                        fontSize: 13,
                        fontFamily: 'Nunito, sans-serif'
                    }}>
                    SAIR</MenuItem>
            </Menu>
        </div>
    );
}