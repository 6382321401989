import React from "react"
import img from "./logo.png"
import styled from 'styled-components';

const Img = styled.img`
margin-top: 10;
margin-left: 30px;
margin-bottom: 0;
height:25px;
`;

export default function Logo() {
    return <Img src={img} alt="Logotipo da nova isp telecom" />
}
