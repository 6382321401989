import { useState } from 'react'
import { useQuery } from "react-query";
import api from '../api'


export default function useFetch({ team }) {
    const [technicalCards, setTechnicalCards] = useState([])

    const { isLoading } = useQuery(
        `/requests_company`,
        async () => {
            const resDigital = await api.post('/api/responsible_team', { "team": team, "company": "DIGITAL" })
            const resNovanet = await api.post('/api/responsible_team', { "team": team, "company": "NOVANET" })
            return { digital: resDigital.data, novanet: resNovanet.data }
        },
        {
            onSuccess: async (data) => {
                const { digital } = data
                const { novanet } = data
                const dataDigital = await consult(digital)
                const dataNovanet = await consult(novanet)
                const dados = dataDigital.concat(dataNovanet)
                setTechnicalCards(dados)
            },
            refetchInterval: 15000,
        })


    async function consult(data) {
        const dataRequests = configData(data)
        const res = await api.post('/api/requests_technical', dataRequests)
        return res.data
    }

    function configData(data) {
        const responsible = data.map((dados) => {
            return dados.name
        })
        return { "responsavel": responsible, "unidade": data[0].company }
    }

    return {
        technicalCards,
        isLoading
    }
}


