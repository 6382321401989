import React from "react";
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';

import {
  Card,
  Container,
  Grid,
  Typography,
  makeStyles,
  CircularProgress
} from "@material-ui/core";
import HeaderApp from "./HeaderApp";
import FooterApp from "./FooterApp";
import useFetch from "../hooks/useFetch";
import Tags from "./Tags";

const colors = {
  'bgPrimary': '#161B22',
  'bgSecundary': '#0D1117',
  'colorAlert': '#ff4747',
  'colorInfo': '#2E7D32',
  'colorHighlight': '#ddd',
  'colorDiscreet': '#333',
  'colorAlternative': '#bbb',
}
const propsTheme = {
  'font': 'Montserrat, sans-serif',
  'fontCard': 'Nunito, sans-serif',
  'sizeIconLarge': 1.5,
  'sizeIconSmall': 4
}

const useStyles = makeStyles({
  container: {
    backgroundColor: colors['bgPrimary'],
    paddingTop: 50,
    margin: 0,
  },
  tpTitle: {
    fontFamily: propsTheme['font'],
    color: colors['colorHighlight'],
    fontSize: 16,
    fontWeight: 'bold',
    verticalAlign: 'middle',
    textAlign: 'center',
    padding: 10
  },
  cardInfo: {
    backgroundColor: colors['bgSecundary'],
    margin: 0,
    padding: 6,
    border: `1px solid ${colors['colorDiscreet']}`,
    boxShadow: `1px 1px 1px ${colors['bgPrimary']}`,
    borderRadius: '2px',
  },
  icon: {
    backgroundColor: colors['bgSecundary'],
    padding: 0,
    color: colors['colorAlternative'],
  },
  nameTitle: {
    backgroundColor: colors['bgSecundary'],
    color: colors['colorAlternative'],
    fontFamily: propsTheme['fontCard'],
    textAlign: 'center',
    paddingLeft: 2,
    fontSize: 15,
    fontWeight: 'bold'
  },
  unidadeTitle: {
    fontFamily: propsTheme['fontCard'],
    backgroundColor: colors['bgSecundary'],
    color: colors['colorAlternative'],
    textAlign: 'left',
    paddingLeft: 2,
    fontSize: 12,
    fontWeigt: 'bold'
  },
  atrasado: {
    backgroundColor: '#D32F2F',
    color: colors['colorHighlight'],
    '& p': {
      fontFamily: propsTheme['fontCard'],
      fontSize: 18,
      fontWeight: 'bold'
    }
  },
  hoje: {
    backgroundColor: colors['colorInfo'],
    color: colors['colorHighlight'],
    '& p': {
      fontFamily: propsTheme['fontCard'],
      fontSize: 18,
      fontWeight: 'bold'
    }
  },
  aFrente: {
    backgroundColor: '#ED6C02',
    color: colors['colorHighlight'],
    '& p': {
      fontFamily: propsTheme['fontCard'],
      fontSize: 18,
      fontWeight: 'bold'
    }
  },
  emPlay: {
    fontFamily: propsTheme['fontCard'],
    fontSize: 12,
    textAlign: 'left',
    fontWeight: 'bold',
    color: colors['colorInfo'],
    backgroundColor: colors['bgSecundary'],
    borderRadius: 0
  },
  semAtividade: {
    fontFamily: propsTheme['fontCard'],
    fontSize: 12,
    textAlign: 'center',
    padding: 5,
    fontWeight: 'bold',
    color: colors['colorAlternative'],
    backgroundColor: colors['bgSecundary'],
    borderRadius: 0
  }
});

function CardsPeople({ title, team }) {
  const { isLoading, technicalCards } = useFetch({ team })
  const classes = useStyles();

  if (isLoading) return <CircularProgress />
  return (
    <>
      <HeaderApp />
      <Container maxWidth="xl" className={classes.container} >
        <Typography className={classes.tpTitle}>
          {title}
        </Typography>
        <Grid container spacing={1}>
          {technicalCards.map((technical, index) => (
            <Grid item xs={technicalCards.length > 6 ? 3 : 4} key={index}>
              <div className={classes.card}>
                <Card className={classes.cardInfo} >
                  <Grid container spacing={1} className={classes.cardTest}>
                    <Grid item xs={12} >
                      <Card className={classes.nameTitle}>
                        <Typography className={classes.nameTitle}>
                          {technical.responsible}
                        </Typography>
                      </Card>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} style={{
                    paddingTop: 3,
                    paddingBottom: 8
                  }}>
                    <Tags
                      tagOne={technical.unidade}
                      tagTwo={technical.route}
                      tagThree={technical.occupation_primary}
                      tagFour={technical.occupation_secundary}
                      colors={colors}
                      propsTheme={propsTheme}
                    />
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={4} >
                      <Card className={classes.atrasado}>
                        <Typography >
                          Atrasado
                        </Typography>
                        <Typography >
                          {team === 'INSTALAÇÃO' ? technical.sla.slaInstalacao.atraso : technical.sla.slaManutencao.atraso}
                        </Typography>
                      </Card>
                    </Grid>
                    <Grid item xs={4} >
                      <Card className={classes.hoje}>
                        <Typography >
                          Hoje
                        </Typography>
                        <Typography >
                          {team === 'INSTALAÇÃO' ? technical.sla.slaInstalacao.hoje : technical.sla.slaManutencao.hoje}
                        </Typography>
                      </Card>
                    </Grid>
                    <Grid item xs={4} >
                      <Card className={classes.aFrente} >
                        <Typography >
                          A frente
                        </Typography>
                        <Typography >
                          {team === 'INSTALAÇÃO' ? technical.sla.slaInstalacao.aFrente : technical.sla.slaManutencao.aFrente}
                        </Typography>
                      </Card>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={2} >
                      <Card className={classes.icon}>
                        {technical.inPlay === false ? <PauseCircleIcon fontSize="large" color="disable" /> : <PlayCircleIcon fontSize="large" color="success" />}
                      </Card>
                    </Grid>
                    <Grid item xs={10}>
                      <Card className={technical.inPlay === false ? classes.semAtividade : classes.emPlay}>
                        {technical.inPlay === false ?
                          <Typography className={classes.semAtividade}>
                            Nenhuma atividade em execução
                          </Typography> :
                          <Typography className={classes.emPlay}>
                            {technical.inPlay.protocolo} <br />
                            {technical.inPlay.start_play}
                          </Typography>
                        }
                      </Card>
                    </Grid>
                  </Grid>
                </Card>
              </div>
            </Grid>
          ))
          }
        </Grid >
      </Container >
      <FooterApp />
    </>
  )
}
export default CardsPeople;
