import { React, useContext } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import CardsPeople from './CardsPeople';
import { QueryClient, QueryClientProvider } from 'react-query'
import NovasDemandas from './NovasDemandas';
import Admin from './Admin';
import Home from './Home';
import Login from './Login';
import { Context } from "../contexts/AuthContext"

const queryClient = new QueryClient()

const theme = {
    'titleSize': 20,
    'fontPrimary': 'Montserrat, sans-serif',
    'fontSecundary': 'Nunito, sans-serif',
    'iconSizeLarge': 1.5,
    'iconSizeSmall': 0.5,
    colors: {
        'bgPrimary': '#161B22',
        'bgSecundary': '#0D1117',
        'fontAlert': '#ff4747',
        'fontInfo': '#2E7D32',
        'fontHighlight': '#ddd',
        'fontDiscreet': '#333',
        'fontAlternative': '#bbb',
    }
}

export default function Router() {
    const { permission } = useContext(Context);
    return (
        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Login />} />
                    <Route path="/home" element={<Home />} />
                    <Route path="/novas-demandas" element={<NovasDemandas />} />
                    <Route path="/manutencao" element={<CardsPeople title='Equipes de Manutenção' url="manutencao" team="MANUTENÇÃO" />} />
                    <Route path="/instalacao" element={<CardsPeople title='Equipes de Instalação' url="instalacao" team="INSTALAÇÃO" />} />
                    <Route path="/infra" element={<CardsPeople title='Equipes de Infraestrutura' url="infra" team="INFRA" />} />
                    <Route path="/admin" element={<Admin theme={theme} permission={permission} />} />
                </Routes>
            </BrowserRouter>
        </QueryClientProvider >
    );
}
