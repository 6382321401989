import { React, useContext, useState } from "react";
import { Context } from "../contexts/AuthContext"
import { useNavigate } from "react-router-dom";
import { Typography, Button, TextField, Container } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import img from './Logo/logo.png'
import styled from 'styled-components';

const Img = styled.img`
height: 75px;
margin-bottom: 20px;
`;

export default function Login() {
    const { handleLogin, authenticated } = useContext(Context);
    let navigate = useNavigate()
    const [user, setUser] = useState("");
    const [pass, setPass] = useState("");
    const [msgErrorLogin, setMsgErrorLogin] = useState(false);

    if (authenticated) navigate('/home')

    return (
        <Container component="article" maxWidth="xs"
            style={{
                marginTop: 150
            }}>
            <Img src={img} alt="Logotipo da nova isp telecom" />
            <Typography style={{
                color: 'white',
                margin: 15,
                fontSize: 15
            }}>
                Live requests
            </Typography>
            <form
                onSubmit={(event) => {
                    event.preventDefault();
                    handleLogin({ user, pass });
                    if (authenticated) {
                        navigate('/home')
                    } else {
                        setTimeout(() => setMsgErrorLogin(true), 750)
                        setTimeout(() => setMsgErrorLogin(false), 3750)
                    }
                }
                }>
                <TextField
                    style={{ backgroundColor: 'white', borderRadius: 5, textAlign: 'center' }}
                    value={user}
                    onChange={(event) => {
                        setUser(event.target.value);
                    }}
                    id="user"
                    name="user"
                    type="text"
                    placeholder="username"
                    required
                    variant="outlined"
                    fullWidth
                />
                <TextField
                    style={{ backgroundColor: 'white', borderRadius: 5 }}
                    value={pass}
                    onChange={(event) => {
                        setPass(event.target.value);
                    }}
                    id="pass"
                    name="pass"
                    placeholder="password"
                    type="password"
                    required
                    variant="outlined"
                    margin="normal"
                    fullWidth
                />
                {msgErrorLogin === true ?
                    <Alert severity="error">
                        <Typography >
                            Usuário ou senha são inválidos
                        </Typography>
                        <Typography >
                            <p>Verifique seu acesso com o adminsitrador</p>
                        </Typography>
                    </Alert> :
                    <Button
                        style={{
                            backgroundColor: '#ddd', borderRadius: 5, fontWeight: 'bold'
                        }}
                        type="submit" fullWidth>
                        ACESSAR
                    </Button>}
            </form>
        </Container>
    )
}