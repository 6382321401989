import React, { useState } from "react";
import api from '../api'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import InfoIcon from '@mui/icons-material/Info';
import PeopleIcon from '@mui/icons-material/People';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import { useQuery } from "react-query";
import {
  Card,
  CardHeader,
  CardContent,
  Container,
  Grid,
  Typography,
  makeStyles,
  CircularProgress
} from "@material-ui/core";

const colors = {
  'bgPrimary': '#161B22',
  'bgSecundary': '#0D1117',
  'colorAlert': '#ff4747',
  'colorInfo': '#6EFF69',
  'colorHighlight': '#ddd',
  'colorDiscreet': '#333',
  'colorAlternative': '#bbb',
}
const propsTheme = {
  'font': 'Montserrat, sans-serif',
  'fontCard': 'Nunito, sans-serif',
  'sizeIconLarge': 1.5,
  'sizeIconSmall': 4
}

const useStyles = makeStyles({
  container: {
    backgroundColor: colors['bgPrimary'],
    padding: 6,
    marginTop: 70
  },
  cardContent: {
    margin: 0,
    padding: 0,
    textAlign: 'left'
  },
  cardContainerTop: {
    margin: 0,
    padding: 5,
    textAlign: 'center'
  },
  cardAlert: {
    backgroundColor: colors['bgSecundary'],
    border: `1px solid ${colors['colorDiscreet']}`,
    margin: 0,
    padding: 0,
    boxShadow: `1px 1px 1px ${colors['bgPrimary']}`,
    borderRadius: '4px',
  },
  cardInfo: {
    backgroundColor: colors['bgSecundary'],
    border: `1px solid ${colors['colorDiscreet']}`,
    margin: 0,
    padding: 0,
    boxShadow: `1px 1px 1px ${colors['bgPrimary']}`,
    borderRadius: '4px',
  },
  tpTitle: {
    color: colors['colorHighlight'],
    verticalAlign: 'middle',
    fontWeight: 'bold',
    fontSize: 22,
    textAlign: 'center',
    fontFamily: propsTheme['font'],
    margin: 0,
    padding: 0
  },
  tpTitleCard: {
    color: colors['colorHighlight'],
    fontSize: 16,
    fontWeight: 'bold',
    fontFamily: propsTheme['fontCard']
  },
  tpsubTitleCard: {
    color: colors['colorAlternative'],
    fontSize: 16,
    textAlign: 'left',
    fontFamily: propsTheme['fontCard']
  },
  customBody: {
    color: colors['colorHighlight'],
    backgroundColor: colors['bgPrimary'],
    fontSize: 13,
    fontWeight: 'bold',
    margin: 0,
    padding: 0,
    '& p': {
      fontFamily: propsTheme['fontCard'],
      margin: 0,
      paddingLeft: 2,
    }
  },
  backgroundBody: {
    paddingLeft: 5,
    paddingTop: 5,
    paddingRight: 5,
    paddingBottom: 15,
    backgroundColor: colors['bgPrimary'],
  },
  cardBody: {
    margin: 5,
    border: `1px solid ${colors['colorDiscreet']}`,
    padding: 0
  },
  icon: {
    margin: 0,
    paddingLeft: 2,
    paddingBottom: 0,
    paddingTop: 6
  }
});

function CardsNovasDemandas({ team, title }) {
  const [protocols, setProtocols] = useState([])
  const classes = useStyles();
  const { isLoading } = useQuery(
    `/${team}`,
    async () => {
      const res = await api.post('/api/requests_tower', { "equipe": team })
      return res.data
    },
    {
      onSuccess: (data) => {
        setProtocols(data)
      },
      refetchInterval: 15000,
    })

  if (isLoading) return <CircularProgress />
  return (
    <Container maxWidth="xl" className={classes.container} >
      <Typography className={classes.tpTitle}>
        {title}
      </Typography>
      <br />
      <Grid container spacing={1}>
        {protocols.map((protocol) => (
          <Grid item xs={6} className={classes.cardContent} key={protocol.protocol}>
            <Card className={protocol.status === 'PENDENTE DE ENCAMINHAMENTO' ? classes.cardAlert : classes.cardInfo} >
              <CardContent className={classes.cardContent}>
                <CardHeader className={classes.cardContainerTop}
                  avatar={
                    protocol.status === 'PENDENTE DE ENCAMINHAMENTO' ?
                      <InfoIcon fontSize="large" color="error" /> :
                      <FiberNewIcon fontSize="large" color="success" />
                  }
                  title={<span className={classes.tpTitleCard}>{protocol.tipo_os}</span>}
                  subheader={<span className={classes.tpsubTitleCard}>{protocol.unidade} - {protocol.protocol}</span>}
                />
                <Card className={classes.cardBody}>
                  <Grid container spacing={1} className={classes.backgroundBody}>
                    <Grid item xs={2} className={classes.customBody} >
                      <p> <PeopleIcon fontSize="large" className={classes.icon} /> </p>
                    </Grid>
                    <Grid item xs={10} className={classes.customBody} >
                      <p>{protocol.title}</p>
                      <p>{protocol.ponto_acesso}</p>
                      <p>{protocol.bairro}</p>
                    </Grid>
                    <Grid item xs={6} className={classes.customBody}>
                      <p> <CalendarTodayIcon className={classes.icon} fontSize="small" /> {protocol.beginning_date}</p>
                    </Grid>
                    <Grid item xs={6} className={classes.customBody}>
                      <p> <CalendarTodayIcon className={classes.icon} fontSize="small" /> {protocol.final_date} </p>
                    </Grid>
                  </Grid>
                </Card>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container >
  )
}
export default CardsNovasDemandas;
