import { React, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import api from '../api'
import { Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    height: 360,
    bgcolor: '#0D1117',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const title = {
    color: 'white',
    fontSize: 14,
    textAlign: 'center',
    margin: 15,
    fontFamily: 'Nunito, sans-serif'
}


export default function ChangePassword() {
    const [open, setOpen] = useState(false);
    const [currentPassword, setCurrentPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [repeatNewPassword, setRepeatNewPassword] = useState('')
    const [isError, setIsError] = useState(false)
    const [isChangePass, setIsChangePass] = useState(false)
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleError = () => {
        setIsError(true)
        setTimeout(() => setIsError(false), 2500)
    }

    const handleChangePass = () => {
        setIsChangePass(true)
        setTimeout(() => handleClose(false), 2500)
    }
    const handlePass = () => {
        if (newPassword === repeatNewPassword) {
            const token = JSON.parse(localStorage.getItem("token"));
            if (token) {
                api.defaults.headers["x-access-token"] = token;
                api.post('/api/change-password', { "currentPassword": currentPassword, "newPassword": newPassword })
                    .then(res => handleChangePass())
                    .catch(err => handleError())
            }
        } else {
            handleError()
        }
    }

    return (
        <div>
            <Button onClick={handleOpen}
                style={{
                    textDecoration: 'none',
                    color: '#333',
                    fontSize: 13,
                    textAlign: 'left',
                    fontFamily: 'Nunito, sans-serif'
                }}
            >Alterar senha</Button>
            <Modal
                tabIndex={-1}
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box tabIndex={-1} sx={style}>
                    <Typography tabIndex={-1} style={title}>
                        Alterar senha de acesso
                    </Typography>
                    <input style={{
                        width: '50%',
                        height: 35,
                        textAlign: 'center',
                        fontSize: 10,
                        color: 'white',
                        fontFamily: 'Nunito, sans-serif',
                        border: 0,
                        margin: 10,
                        backgroundColor: '#161B22'
                    }}
                        tabIndex={0}
                        id='currentPassword'
                        name='currentPassword'
                        value={currentPassword}
                        onChange={ev => setCurrentPassword(ev.target.value)}
                        placeholder='Senha atual'
                        type="password" />
                    <input style={{
                        width: '50%',
                        height: 35,
                        textAlign: 'center',
                        fontSize: 10,
                        color: 'white',
                        margin: 10,
                        fontFamily: 'Nunito, sans-serif',
                        border: 0,
                        backgroundColor: '#161B22'
                    }}
                        tabIndex={1}
                        id='newPassword'
                        name='newPassword'
                        value={newPassword}
                        onChange={ev => setNewPassword(ev.target.value)}
                        placeholder='Nova senha'
                        type="password" />

                    <input style={{
                        width: '50%',
                        height: 35,
                        textAlign: 'center',
                        fontSize: 10,
                        color: 'white',
                        margin: 10,
                        fontFamily: 'Nunito, sans-serif',
                        border: 0,
                        backgroundColor: '#161B22'
                    }}
                        tabIndex={2}
                        id='repeatNewPassword'
                        name='repeatNewPassword'
                        value={repeatNewPassword}
                        onChange={ev => setRepeatNewPassword(ev.target.value)}
                        placeholder='Repita nova senha'
                        type="password" />

                    {isError ?
                        <Alert severity="error">
                            <Typography >
                                Senha atual inválida ou nova senha divergente
                            </Typography>
                        </Alert> : <br />}
                    {isChangePass ?
                        <Alert severity="success">
                            <Typography >
                                Troca efetuada com sucesso
                            </Typography>
                        </Alert> : <br />}
                    <Button style={{
                        width: '50%',
                        height: 35,
                        textAlign: 'center',
                        fontSize: 11,
                        fontWeight: 'bold',
                        color: 'black',
                        margin: 10,
                        fontFamily: 'Nunito, sans-serif',
                        border: 0,
                        backgroundColor: '#6EFF69'
                    }}
                        tabIndex={3}
                        onClick={handlePass}>Alterar</Button>
                    <Button
                        style={{
                            width: '50%',
                            height: 35,
                            textAlign: 'center',
                            fontSize: 11,
                            fontWeight: 'bold',
                            color: 'black',
                            margin: 2,
                            fontFamily: 'Nunito, sans-serif',
                            border: 0,
                            backgroundColor: '#ff4747'
                        }}
                        tabIndex={4}
                        onClick={handleClose}>sair</Button>
                </Box>
            </Modal>
        </div >
    );
}