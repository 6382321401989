import React from 'react'
import styled from 'styled-components'

const Footer = styled.footer`
position: fixed;
left: 0;
bottom: 0;
width: 100%;
height: 30px;
background-color:#0D1117;
color: white;
text-align: center;
font-size: 18px;
`;

const Text = styled.p`
position: fixed;
font-family: 'Montserrat', sans-serif;
margin-top: 8px;
width: 100%;
background-color:#0D1117;
color: white;
text-align: center;
font-size: 10px;
`;

function FooterApp() {
    return (
        <Footer>
            <Text>developed by <strong>elbertlucas</strong></Text>
        </Footer>
    )
}

export default FooterApp
