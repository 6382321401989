import { React, useEffect, useState } from 'react';
import validator from '../hooks/validator'
import api from '../api'
import { Button } from '@mui/material';
import SelectResponsible from './SelectResponsible'
import { Select, MenuItem } from "@material-ui/core"
import { useSnackbar } from 'notistack';

export default function CadastrarEquipes({ theme }) {

    const [responsible, setResponsible] = useState([])
    const [team, setTeam] = useState('')
    const [unidade, setUnidade] = useState('')
    const [route, setRoute] = useState('')
    const [occupationPrimary, setOccupationPrimary] = useState('')
    const [occupationSecundary, setOccupationSecundary] = useState('')
    const [updateTable, setUpdateTable] = useState(false)
    const [rowsIsSelected, setRowsIsSelected] = useState([])
    const { enqueueSnackbar } = useSnackbar();

    const handleInsertRow = function () {
        const data = { "name": rowsIsSelected, "team": team, "company": unidade, "options": { "route": route, "occupation_primary": occupationPrimary, "occupation_secundary": occupationSecundary } }
        if (validator(data)) {
            api.post('/api/responsible_requests', data)
            setRowsIsSelected([''])
            setTeam('')
            setUnidade('')
            setRoute('')
            setOccupationPrimary('')
            setOccupationSecundary('')
            setUpdateTable(!updateTable)
            enqueueSnackbar('Cadastrado com sucesso!', { "variant": "success" });
        } else {
            enqueueSnackbar('Cadastrado inválido, somente a atividade secundária é opcional', { "variant": "error" });
        }
    }

    useEffect(() => {
        api.get('/api/extract_responsible').then((data) => setResponsible(data.data))
    }, [updateTable])

    const BottonStick = function () {
        return (
            <Button
                style={{
                    border: '1px dashed #222',
                    backgroundColor: '#0D1117',
                    color: '#ddd',
                    paddingTop: 3,
                    paddingBottom: 3,
                    paddingleft: 10,
                    paddingRight: 10,
                    marginTop: 15,
                    fontWeight: 'Bold'
                }}
                onClick={ev => handleInsertRow('success')}>Inserir Colaborador</Button>
        )
    }

    return (
        <>
            <div style={{
                height: 250,
                width: '100%',
            }}>
                <SelectResponsible rows={responsible} setRows={setRowsIsSelected} />
                <p
                    style={{
                        fontFamily: theme.fontSecundary,
                        fontSize: 12,
                        marginTop: 10,
                        marginBottom: 0,
                        textAlign: 'left',
                        color: theme.colors.fontAlternative
                    }}>*Selecione a equipe:</p>
                <Select
                    style={{
                        backgroundColor: theme.colors.bgPrimary,
                        fontFamily: theme.fontSecundary,
                        color: 'white',
                        marginTop: 5,
                        height: 35,
                        width: '100%',
                        background: '',
                        fontSize: 11,
                        fontWeight: 'Bold'
                    }}
                    labelId="team"
                    value={team}
                    id="team"
                    label="Equipe"
                    onChange={ev => setTeam(ev.target.value)}
                >
                    <MenuItem style={{
                        backgroundColor: theme.colors.bgPrimary,
                        fontFamily: theme.fontSecundary,
                        color: theme.colors.fontHighlight,
                        margin: 0,
                        padding: 5,
                        fontWeight: 'bold',
                        fontSize: 14
                    }}
                        value={'MANUTENÇÃO'}>MANUTENÇÃO</MenuItem>
                    <MenuItem style={{
                        backgroundColor: theme.colors.bgPrimary,
                        fontFamily: theme.fontSecundary,
                        color: theme.colors.fontHighlight,
                        margin: 0,
                        padding: 5,
                        fontWeight: 'bold',
                        fontSize: 14
                    }}
                        value={'INSTALAÇÃO'}>INSTALAÇÃO</MenuItem>
                    <MenuItem style={{
                        backgroundColor: theme.colors.bgPrimary,
                        fontFamily: theme.fontSecundary,
                        color: theme.colors.fontHighlight,
                        margin: 0,
                        padding: 5,
                        fontWeight: 'bold',
                        fontSize: 14
                    }}
                        value={'INFRA'}>INFRA</MenuItem>
                </Select>
                <p
                    style={{
                        fontFamily: theme.fontSecundary,
                        fontSize: 12,
                        marginTop: 10,
                        marginBottom: 0,
                        textAlign: 'left',
                        color: theme.colors.fontAlternative
                    }}>*Selecione a rota:</p>
                <Select
                    style={{
                        backgroundColor: theme.colors.bgPrimary,
                        fontFamily: theme.fontSecundary,
                        color: 'white',
                        marginTop: 5,
                        height: 35,
                        width: '100%',
                        background: '',
                        fontSize: 11,
                        fontWeight: 'Bold'
                    }}
                    labelId="route"
                    value={route}
                    id="route"
                    label="Secundária"
                    onChange={ev => setRoute(ev.target.value)}
                >
                    <MenuItem style={{
                        backgroundColor: theme.colors.bgPrimary,
                        fontFamily: theme.fontSecundary,
                        color: theme.colors.fontHighlight,
                        margin: 0,
                        padding: 5,
                        fontWeight: 'bold',
                        fontSize: 14
                    }}
                        value={'FLEX'}>FLEX</MenuItem>
                    <MenuItem style={{
                        backgroundColor: theme.colors.bgPrimary,
                        fontFamily: theme.fontSecundary,
                        color: theme.colors.fontHighlight,
                        margin: 0,
                        padding: 5,
                        fontWeight: 'bold',
                        fontSize: 14
                    }}
                        value={'ROTA 1'}>ROTA 1</MenuItem>
                    <MenuItem style={{
                        backgroundColor: theme.colors.bgPrimary,
                        fontFamily: theme.fontSecundary,
                        color: theme.colors.fontHighlight,
                        margin: 0,
                        padding: 5,
                        fontWeight: 'bold',
                        fontSize: 14
                    }}
                        value={'ROTA 2'}>ROTA 2</MenuItem>
                    <MenuItem style={{
                        backgroundColor: theme.colors.bgPrimary,
                        fontFamily: theme.fontSecundary,
                        color: theme.colors.fontHighlight,
                        margin: 0,
                        padding: 5,
                        fontWeight: 'bold',
                        fontSize: 14
                    }}
                        value={'ROTA 3'}>ROTA 3</MenuItem>
                    <MenuItem style={{
                        backgroundColor: theme.colors.bgPrimary,
                        fontFamily: theme.fontSecundary,
                        color: theme.colors.fontHighlight,
                        margin: 0,
                        padding: 5,
                        fontWeight: 'bold',
                        fontSize: 14
                    }}
                        value={'ROTA 4'}>ROTA 4</MenuItem>
                </Select>
                <p
                    style={{
                        fontFamily: theme.fontSecundary,
                        fontSize: 12,
                        marginTop: 10,
                        marginBottom: 0,
                        textAlign: 'left',
                        color: theme.colors.fontAlternative
                    }}>*Selecione a unidade:</p>
                <Select
                    style={{
                        backgroundColor: theme.colors.bgPrimary,
                        fontFamily: theme.fontSecundary,
                        color: 'white',
                        marginTop: 5,
                        height: 35,
                        width: '100%',
                        background: '',
                        fontSize: 11,
                        fontWeight: 'Bold'
                    }}
                    labelId="team"
                    value={unidade}
                    id="team"
                    label="Equipe"
                    onChange={ev => setUnidade(ev.target.value)}
                >
                    <MenuItem style={{
                        backgroundColor: theme.colors.bgPrimary,
                        fontFamily: theme.fontSecundary,
                        color: theme.colors.fontHighlight,
                        margin: 0,
                        padding: 5,
                        fontWeight: 'bold',
                        fontSize: 14
                    }}
                        value={'DIGITAL'}>DIGITAL</MenuItem>
                    <MenuItem style={{
                        backgroundColor: theme.colors.bgPrimary,
                        fontFamily: theme.fontSecundary,
                        color: theme.colors.fontHighlight,
                        margin: 0,
                        padding: 5,
                        fontWeight: 'bold',
                        fontSize: 14
                    }}
                        value={'NOVANET'}>NOVANET</MenuItem>
                </Select>
                <p
                    style={{
                        fontFamily: theme.fontSecundary,
                        fontSize: 12,
                        marginTop: 10,
                        marginBottom: 2,
                        textAlign: 'left',
                        color: theme.colors.fontAlternative
                    }}>*Selecione a atividade principal:</p>
                <input
                    style={{
                        backgroundColor: theme.colors.bgSecundary,
                        fontFamily: theme.fontSecundary,
                        color: 'white',
                        textAlign: 'center',
                        fontWeight: 'bold',
                        border: 1,
                        fontSize: 14,
                        width: '100%',
                        height: 35,
                        boxShadow: '0 1px 0 0 black'
                    }}
                    id="occupationPrimary"
                    name='occupationPrimary'
                    type='text'
                    value={occupationPrimary}
                    onChange={ev => setOccupationPrimary(ev.target.value)}
                    >
                </input>
                <p
                    style={{
                        fontFamily: theme.fontSecundary,
                        fontSize: 12,
                        marginTop: 10,
                        marginBottom: 2,
                        textAlign: 'left',
                        color: theme.colors.fontAlternative
                    }}>Selecione a atividade secundária:</p>
                <input
                    style={{
                        backgroundColor: theme.colors.bgSecundary,
                        fontFamily: theme.fontSecundary,
                        color: 'white',
                        textAlign: 'center',
                        fontWeight: 'bold',
                        border: 1,
                        fontSize: 14,
                        width: '100%',
                        height: 35,
                        boxShadow: '0 1px 0 0 black'
                    }}
                    id="occupationSecundary"
                    name='occupationSecundary'
                    type='text'
                    value={occupationSecundary}
                    onChange={ev => setOccupationSecundary(ev.target.value)}
                    >
                </input>
                <BottonStick />
            </div>
        </>
    )
};