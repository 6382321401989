
export default function validator(responsible) {

    function responsibleIsValid(data) {
        if (data.name === []) return false
        if (data.team === '') return false
        if (data.company === '') return false
        if (data.options.route === []) return false
        if (data.options.occupation_primary === []) return false
        return true
    }

    const isValid = responsibleIsValid(responsible)
    return isValid
}


