import { React } from 'react'
import LocationOnIcon from '@mui/icons-material/LocationOn';

export default function Tags({ tagOne, tagTwo, tagThree, tagFour, colors, propsTheme }) {

    function routeColor(data) {
        switch (data) {
            case 'Rota 1':
                return colors['colorInfo']
            case 'Rota 2':
                return colors['colorAlert']
            case 'Rota 3':
                return '#A40'
            default:
                return colors['colorDiscreet']
        }
    }

    const styleTags = {
        color: 'white',
        backgroundColor: routeColor(tagTwo),
        fontFamily: propsTheme['fontCard'],
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 12,
        paddingLeft: 5,
        paddingRight: 5,
        margin: 5
    }
    return (
        <>
            <span style={styleTags}>
                {tagOne}
            </span>
            <span style={styleTags} >
                <LocationOnIcon style={{ fontSize: 11, paddingRight: 2 }} />
                {tagTwo} </span>
            <span style={styleTags}> {tagThree} </span>
            {tagFour ?
                <span style={styleTags}> {tagFour} </span> :
                <br />
            }
        </>
    )
}

