import React from "react";
import HeaderApp from './HeaderApp'
import FooterApp from './FooterApp'
import CardsNovasDemandas from './CardsNovasDemandas';
import {
    Grid,
} from "@material-ui/core";

export default function NovasDemandas() {

    return (
        <>
            <HeaderApp />
            <Grid container spacing={0}>
                <Grid item xs={6}>
                    <CardsNovasDemandas team='INFRA' title='Novas demandas - Infraestrutura' />
                </Grid>
                <Grid item xs={6}>
                    <CardsNovasDemandas team='MANUTENCAO' title='Novas demandas - Manutenção' />
                </Grid>
            </Grid>
            <FooterApp />
        </>
    )
}